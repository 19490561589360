import { useNow } from "@vueuse/core";
import { defineStore } from "pinia";

export const useRainStore = defineStore("rain", () => {
    const ws = useSocketStore()();
    const now = useNow();

    const rain = ref<RainInterface|null>(null);
    const joined = ref(false);
    const settings = ref<{
        defaultXpMultiplier: number;
        rainXpMultiplier: number;
        minutesUntilProgrssBar: number;
        duration: number;
        kycRequired: boolean;
    }>({
        defaultXpMultiplier: 0,
        rainXpMultiplier: 0,
        minutesUntilProgrssBar: 0,
        duration: 0,
        kycRequired: false
    });

    const secondsLeft = computed(() => rain.value
        ? Math.max(0, Math.round((new Date(rain.value.end_at).getTime() - now.value.getTime()) / 1000))
        : null
    );

    const totalDuration = computed(() => rain.value
        ? Math.max(0, Math.round((new Date(rain.value.end_at).getTime() - new Date(rain.value.created_at).getTime()) / 1000))
        : null
    );

    const formattedTimeLeft = computed(() => {
        if (!secondsLeft.value) { return "00:00"; }

        const minutes = Math.floor(secondsLeft.value / 60);
        const seconds = secondsLeft.value % 60;

        return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    });

    ws.register("rain:index", (data: { rain: RainInterface, settings: any, joined: boolean }) => {
        rain.value = data.rain;
        settings.value = data.settings;
        joined.value = data.joined;
    });

    ws.register("rain:update", (data: { rain: RainInterface }) => {
        if (rain.value) {
            rain.value.coins = data.rain.coins;
        }
    });

    if (getCurrentInstance()) {
        ws.socket?.emit("rain:index");
    }

    return {
        rain,
        settings,
        secondsLeft,
        totalDuration,
        formattedTimeLeft,
        joined
    };
});
